


function Scoreboard(props) {
    const {player, agent, scoreboard, changeSymbol} = props;


    return(
        <div className='scoreboard-container'>
            <div className='score-info-container'>
                <div className='players-text-container'>
                    <img draggable="false" src={require(`../../images/TicTacToe/you-word.png`)} alt="Player Text" className='ttt-scoreboard-text' />
                </div>

                <img draggable="false" src={require(`../../images/TicTacToe/symbol_${player}.png`)} alt="player" className='scoreboard-symbol' onClick={changeSymbol} />
                <div className='scoreboard-box'>
                    <img draggable="false" src={require(`../../images/TicTacToe/number${scoreboard.player === 0 ? "0" : (scoreboard.player / 10).toString()[0]}.png`)} alt="player" className='ttt-scores' />
                    <img draggable="false" src={require(`../../images/TicTacToe/number${scoreboard.player % 10 === 0 ? "0" : (scoreboard.player / 10).toString()[2]}.png`)} alt="player" className='ttt-scores' />
                </div>
            </div>
            <div className='score-info-container draws-info-container'>
                <div className='draws-text-container'>
                    <img draggable="false" src={require(`../../images/TicTacToe/tie-word.png`)} alt="Player Text" className='ttt-scoreboard-text' />
                </div>

                <img draggable="false" src={require(`../../images/TicTacToe/number${scoreboard.draws === 0 ? "0" : (scoreboard.draws / 10).toString()[0]}.png`)} alt="player" className='ttt-scores' />
                <img draggable="false" src={require(`../../images/TicTacToe/number${scoreboard.draws % 10 === 0 ? "0" : (scoreboard.draws / 10).toString()[2]}.png`)} alt="player" className='ttt-scores' />

            </div>
            <div className='score-info-container'>
                <div className='players-text-container'>
                    <img draggable="false" src={require(`../../images/TicTacToe/agent-word.png`)} alt="Player Text" className='ttt-scoreboard-text' />
                </div>

                <div className='scoreboard-box'>
                    <img draggable="false" src={require(`../../images/TicTacToe/number${scoreboard.agent === 0 ? "0" : (scoreboard.agent / 10).toString()[0]}.png`)} alt="player" className='ttt-scores' />
                    <img draggable="false" src={require(`../../images/TicTacToe/number${scoreboard.agent % 10 === 0 ? "0" : (scoreboard.agent / 10).toString()[2]}.png`)} alt="player" className='ttt-scores' />
                </div>
                <img draggable="false" src={require(`../../images/TicTacToe/symbol_${agent}.png`)} alt="agent" className='scoreboard-symbol' onClick={changeSymbol} />
            </div>
        </div>
    )
}


export default Scoreboard;


import DOLLAR_SIGN from '../../images/Blackjack/blackjack-dollar-sign.png'


function Number(props) {

    const {type, number} = props;

    const NUMBER_ARRAY = [...number.toString()];

    if(type === 'hand') {
        return (
            <div className="blackjack-hand-count">
                {NUMBER_ARRAY.map((number, numberID) => 
                    <img draggable='false' key={numberID} src={require(`../../images/Blackjack/blackjack-number-${number}.png`)} alt={number} className="blackjack-number" />
                )}
            </div>
        )
    }

    if(type === 'bet') {
        return (
            <div className="blackjack-bet-amount">
                <img draggable='false' src={DOLLAR_SIGN} alt="dollar sign" className='blackjack-number' />
                {NUMBER_ARRAY.map((number, numberID) =>
                    <img draggable='false' key={numberID} src={require(`../../images/Blackjack/blackjack-number-${number}.png`)} alt={number} className="blackjack-number" />
                )}
            </div>
        )
    }

    if(type === 'money') {
        return (
            <div className='blackjack-bankroll-money'>
                <img draggable='false' src={DOLLAR_SIGN} alt="dollar sign" className='blackjack-number' />
                {NUMBER_ARRAY.map((number, numberID) =>
                    <img draggable='false' key={numberID} src={require(`../../images/Blackjack/blackjack-number-${number}.png`)} alt={number} className="blackjack-number" />
                )}
            </div>
        )
    }

}


export default Number;
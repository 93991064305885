
import { useEffect, useState } from "react";

import tictactoe from '../../images/Home/tictactoe.png'
import maze from '../../images/Home/maze.png'
import colors from '../../images/Home/4colors.png'
import memorycards from '../../images/Home/memorycards.png'
import blackjack from '../../images/Home/blackjack.png'

import playButton from '../../images/Home/homepage-play-button.png';
import playButtonHover from '../../images/Home/homepage-play-button-hover.png';

const IMAGES = {
    "tictactoe": tictactoe,
    "maze": maze,
    "4colors": colors,
    "memorycards": memorycards,
    "blackjack": blackjack
}

const DESKTOP_ONLY = new Set(["maze"])

function GameCard(props) {
    
    const {game, showGame, direction, onClick} = props;
    const [render, setRender] = useState(false);
    const [buttonHover, setButtonHover] = useState(false);

    let [touchScreen] = useState(window.matchMedia("(pointer: coarse)").matches);


    useEffect(() => {
        if(game === showGame) {
            setRender(true);
        }
    }, [game, showGame])


    function onAnimationEnd() {
        if(game !== showGame) {
            setRender(false);
        }
    }


    return(
        render && (
            <div 
                className={`slider-game-card slider-${game} ${game === showGame ? "main-game-card" : ""}`}
                style={direction === "right" ? { animation: `${(game === showGame) ? 'gameCardRightMount .25s ease' : 'gameCardLeftUnmount .25s ease'}` } : 
                        direction === "left" ? { animation: `${(game === showGame) ? 'gameCardLeftMount .25s ease' : 'gameCardRightUnmount .25s ease'}` } : null
                }
                onAnimationEnd={onAnimationEnd}
                >
                    
                <img draggable="false" src={IMAGES[game]} alt={game} className="game-card-image" />
                
                {(DESKTOP_ONLY.has(game) && touchScreen) &&
                    <div className="desktop-only-container">
                        <img src={require('../../images/Home/desktop-only.png')} alt="DESKTOP ONLY" className="desktop-only-image" />
                    </div> 
                }
                
                <img 
                    draggable="false"
                    src={require(`../../images/Home/${game}-homepage-title.png`)} 
                    alt={game} 
                    className="game-card-title" 
                    style={{display: `${game !== showGame ? 'none' : 'inline'}`}}
                    />

                <button 
                    className={`game-card-play-button ${DESKTOP_ONLY.has(game) && touchScreen ? "desktop-only-button" : "desktop-mobile-button"} `} 
                    onClick={(DESKTOP_ONLY.has(game) && touchScreen) ? undefined : onClick}
                    style={{ display: `${game !== showGame ? 'none' : 'inline-block'}` }}
                    >
                    <img 
                        draggable="false"
                        id={game}
                        src={!buttonHover ? playButton : playButtonHover}
                        alt="play"
                        onMouseEnter={() => {setButtonHover(true)}}
                        onMouseLeave={() => {setButtonHover(false)}}

                    />
                </button>
               
            </div>
        )
    )
}

export default GameCard;

import { useCallback, useEffect, useState } from 'react';
import './Maze.css'

import Board from './Board';
import Timer from '../../components/Timer';
import Play from '../../components/Play';

function Maze(props) {
    //console.log("MAZE !")

    const {play, stop} = props;

    const [start, setStart] = useState(false);
    const [results, setResults] = useState("");

    useEffect(() => {
        return () => {
            stop();
        }   
    },[stop])

    function startGame() {
        setStart(true);
        play({id: 'music'})
    }

    
    const endGame = useCallback((RESULTS) => {
        stop();
        if (RESULTS === 'lost') {
            play({id: 'lose'})
        }
        else {
            play({ id: 'win' })
        }

        setStart(false);
        setResults(RESULTS);
    }, [play, stop])


    return (
        <div className='maze-main-container'>
            {props.children}
            <Play play={start} results={results} onClick={startGame} />

            <Board START={start} endGame={endGame}>
                <Timer start={start} time={30} endGame={endGame} />
            </Board>
        </div>
    )

}

export default Maze;


import HEART from '../../images/MemoryCards/heart.png'

function Lives(props) {

    const {lives} = props;

    const LIVES = [...lives.toString()];

    return (
        lives !== 0 &&
            <div className='memory-cards-lives'>
                <img src={HEART} alt="heart" />
                {LIVES.map((number, numberID) =>
                    <img draggable="false" key={numberID} src={require(`../../images/Numbers/number-${number}-dark.png`)} alt={number} />

                )}
            </div>
    )

}

export default Lives;


import CARD_BACK from '../../images/Blackjack/card-back.png';

function Card(props) {

    const {card, cardID, count} = props;

    return (
        <div className="blackjack-card" style={{ left: `${(cardID * 30) - (15 * (count - 1))}%` }}>
            <div className={`blackjack-card-face ${card[2] === true ? 'blackjack-card-flip' : "" }`}>
                <img src={CARD_BACK} alt="card" className='blackjack-card-back' />
                <img src={require(`../../images/Blackjack/${card[0]}-${card[1]}.png`)} alt={card[0] + card[1]} className='blackjack-card-front' />
            </div>
        </div>
    )

}

export default Card;
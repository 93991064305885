
import { useEffect, useRef, useState } from 'react';

import './Colors.css';
import ColorButtons from './ColorButtons';
import Level from './Level';
import Play from '../../components/Play';
//import Highscore from './Highscore';


function Colors(props) {
    //console.log("MAIN RENDER!!")
    const {play} = props;

    const [start, setStart] = useState(false);
    const [level, setLevel] = useState(0);
    const [results, setResults] = useState("");

    //const [highscore, setHighscore] = useState(0);

    const TIMEOUT = useRef();

    useEffect(() => {
        return () => {
            clearTimeout(TIMEOUT.current);
        }
    }, [])

    function startGame() {
        setStart(true);
        setLevel(1);
        setResults("")
    }

    function getGameResults(result) {
        if(result === 'win') {
            TIMEOUT.current = setTimeout(() => {
                play({id: 'levelup'})
                setLevel(level => level + 1);
            }, 700)
        }
        else {
            /*
            if(level > highscore) {
                console.log("NEW HIGHSCORE!");
                localStorage.setItem('highscore', level);
                setHighscore(level);
            }
            */

            play({ id: 'lose' })
            setResults(result);
            setStart(false);
        }
    }


    return(
        <div className="colors-main-container">
            {props.children}
            <Play play={start} results={results} onClick={startGame} />
           
            <ColorButtons start={start} level={level} sendGameResults={getGameResults} play={play}>
                <Level level={level} />
                {/*<Highscore highscore={highscore} />*/}
            </ColorButtons>
        </div>
    )
}

export default Colors;


function Level(props) {
    const {level} = props;

    return(
        <div className="colors-level-container">
            <img draggable="false" src={require('../../images/level-art.png')} alt="level" className='colors-level-word-image'/>
            <img draggable="false" src={require(`../../images/4Colors/4colors-number${level === 0 ? "0" : (level / 10).toString()[0]}.png`)} alt="number" className='colors-level-image' />
            <img draggable="false" src={require(`../../images/4Colors/4colors-number${level % 10 === 0 ? "0" : (level / 10).toString()[2]}.png`)} alt="number" className='colors-level-image' />   
        </div>
    )
}


export default Level;
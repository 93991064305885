
import { useEffect, useRef, useState } from 'react';

import Card from "./Card";
import Bankroll from './Bankroll';
import Number from './Number';

import './Blackjack.css'

import WIN_IMAGE from '../../images/Blackjack/blackjack-you-win.png'
import LOSE_IMAGE from '../../images/Blackjack/blackjack-you-lose.png'
import PUSH_IMAGE from '../../images/Blackjack/blackjack-push.png'

import PLACE_YOUR_BET from '../../images/Blackjack/place-your-bet.png'

import HIT_IMAGE from '../../images/Blackjack/hit-button.png'
import STAND_IMAGE from '../../images/Blackjack/stand-button.png'
import DOUBLE_IMAGE from '../../images/Blackjack/double-button.png'


const CARD_VALUES = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];
const CARD_SYMBOLS = ['D', 'H', 'S', 'C'];

/*
    COUNTS BLACKJACK CARDS AND RETURNS TOTAL COUNT.
*/
function countCards(CARDS) {
    let TOTAL = 0;
    let ACE = false;
    let SOFT = false;

    for(let card = 0; card < CARDS.length; card++) {
        if(CARDS[card][0] === 'A' && !ACE) {
            ACE = true;
            SOFT = true;
            TOTAL += 11;
        }
        else if(CARDS[card][0] === 'A' && ACE) {
            TOTAL += 1;
        }
        else {
            let VALUE = parseInt(CARDS[card][0], 10);
           
            if(isNaN(VALUE)) {
                TOTAL += 10;
            }
            else {
                TOTAL += VALUE;
            }
        }

        if(TOTAL > 21 && ACE && SOFT) {
            TOTAL -= 10;
            SOFT = false;
        }
    }

    return TOTAL;
}



function Blackjack(props) {
    //console.log("BLACKJACK RENDER !");
    const {play} = props;

    const [money, updateMoney] = useState(100);
    const [bet, updateBet] = useState(0);

    const [allBettingChips, updateAllBettingChips] = useState([]);
    const [bettingChips, updateBettingChips] = useState([]);

    const [start, setStart] = useState(false);
    const [winner, setWinner] = useState(null);

    //DEALER AND PLAYERS HANDS
    const [dealer, updateDealer] = useState({cards: [], total: 0});    
    const [player, updatePlayer] = useState({cards: [], total: 0});


    const [userInput, setUserInput] = useState(false);

    const INITIAL_RENDER = useRef(true);

    //CARD DECK
    const CARDS = useRef([]);

    //INTERVAL USED TO DRAW CARDS AT THE BEGINNING OF EACH GAME
    const INTERVAL = useRef();

    const TIMEOUT = useRef(); 

    const BET = useRef();
    const DOUBLE = useRef(false);
    const STAND = useRef(false);
    

    // CREATE DECK AND SHUFFLE CARDS ON FIRST RENDER
    useEffect(() => {
        shuffleCards();

        return () => {
            clearInterval(INTERVAL.current);
            clearTimeout(TIMEOUT.current);
        }
    }, [])


    useEffect(() => {
        //console.log("USE EFFECT ! ");
        if(INITIAL_RENDER.current === true) {
            INITIAL_RENDER.current = false;
            return;
        }

        function END_GAME(results = null) {
            let WINNER = results;
            //console.log(dealer.total, player.total);

            if(WINNER === null) {
                if(dealer.total === player.total) {
                    WINNER = 'draw';
                }
                else if(dealer.total > player.total) {
                    WINNER = 'dealer';
                }
                else if(dealer.total < player.total) {
                    WINNER = 'player';
                }
            }
            
            if(WINNER === 'dealer') {
                //PLAY SOUND
                play({ id: 'lose' })
                
                setWinner('dealer');
                updateBettingChips([]);
                updateBet(0);
            }
            else if(WINNER === 'player') {
                //PLAY SOUND
                play({ id: 'win' })

                setWinner('player');
                updateBettingChips([]);
                updateBet(0);
                if(player.cards.length === 2 && player.total === 21) {
                    updateMoney(money => money + Math.floor(BET.current * 2.5) );
                }
                else {
                    updateMoney(money => money + BET.current * 2);
                }                
            }
            else if(WINNER === 'draw') {
                //PLAY SOUND
                play({ id: 'push' })

                setWinner('draw');
                updateBettingChips([]);
                updateBet(0);
                updateMoney(money => money + BET.current);
            }
        }
        
        //CHECK IF PLAYER GETS BLACKJACK AT THE BEGINNING OF GAME
        if(dealer.cards.length === 2 && player.cards.length === 2 && !STAND.current) {
            //END GAME RIGHT AWAY IF PLAYER HAS BLACKJACK WITH FIRST 2 CARDS.
            if(player.total === 21) {
                if (!dealer.cards[1][2]) {
                    TIMEOUT.current = setTimeout(() => {
                        updateDealer(prev => {
                            let COPY = prev.cards;
                            COPY[1][2] = true;

                            let TOTAL = countCards(COPY);
                            console.log(TOTAL);
                            return { cards: COPY, total: TOTAL }
                        })
                    }, 750)
                }
                else {
                    TIMEOUT.current = setTimeout(() => {
                        END_GAME();
                    }, 750)
                }
            }
            else {
                setUserInput(true);
            }
        }
        //CHECK IF PLAYER BUSTS WHEN PLAYER DECIDES TO HIT 
        else if(dealer.cards.length === 2 && player.cards.length > 2 && !STAND.current && !DOUBLE.current) {
            //PLAYER BUSTS
            if(player.total > 21) {
                if (!dealer.cards[1][2]) {
                    console.log('BLACKJACK !');

                    TIMEOUT.current = setTimeout(() => {
                        //FLIP DEALER CARD
                        updateDealer(prev => {
                            let COPY = prev.cards;
                            COPY[1][2] = true;

                            let TOTAL = countCards(COPY);

                            return { cards: COPY, total: TOTAL }
                        })
                    }, 750)

                }
                else {
                    TIMEOUT.current = setTimeout(() => {
                        END_GAME('dealer');
                    }, 750)
                }
            }
            else {
                TIMEOUT.current = setTimeout(() => {
                    setUserInput(true);
                }, 500)
                
            }



        }
        else if(dealer.cards.length >= 2 && player.cards.length >= 2 && STAND.current) {
            //DEALER COUNT IS BETWEEN 17-21
            if (dealer.total >= 17 && dealer.total <= 21) {
                clearInterval(INTERVAL.current);

                //IF PLAYER DOUBLED DOWN, FLIP CARD AND RESET DOUBLE REF VARIABLE TO FALSE.
                if(DOUBLE.current) {
                    TIMEOUT.current = setTimeout(() => {
                        DOUBLE.current = false;
                        //FLIP PLAYER CARD
                        updatePlayer(prev => {
                            let COPY = prev.cards;
                            COPY[2][2] = true;

                            let TOTAL = countCards(COPY);

                            return { cards: COPY, total: TOTAL }
                        })
                    }, 750)
                }
                else {
                    if(player.total <= 21) {
                        TIMEOUT.current = setTimeout(() => {
                            END_GAME();
                        }, 750)
                    }
                    else {
                        TIMEOUT.current = setTimeout(() => {
                            END_GAME('dealer');
                        }, 750)
                    }
                    
                }               
            }

            //DEALER BUSTS
            if (dealer.total > 21) {
                clearInterval(INTERVAL.current);
                if (DOUBLE.current) {
                    TIMEOUT.current = setTimeout(() => {
                        DOUBLE.current = false;
                        //FLIP PLAYER CARD
                        updatePlayer(prev => {
                            let COPY = prev.cards;
                            COPY[2][2] = true;

                            let TOTAL = countCards(COPY);

                            return { cards: COPY, total: TOTAL }
                        })
                    }, 750)
                }
                else {
                    if (player.total <= 21) {
                        TIMEOUT.current = setTimeout(() => {
                            END_GAME('player');
                        }, 750)
                    }
                    else {
                        TIMEOUT.current = setTimeout(() => {
                            END_GAME('dealer');
                        }, 750)
                    }

                }
            }
        }
        

    }, [dealer, player, play])


    useEffect(() => {
        if(winner !== null) {
            TIMEOUT.current = setTimeout(() => {
                //console.log("RESET GAME !");
                resetGame();
            }, 25000)
        }
    }, [winner])

    useEffect(() => {
        BET.current = bet;
    }, [bet])


    function resetGame() {
        updateDealer({cards: [], total: 0});
        updatePlayer({cards: [], total: 0});
        setWinner(null);
        setStart(false);

        updateAllBettingChips([]);

        DOUBLE.current = false;
        STAND.current = false;
    }



    function placeBet(CHIP) {

        //PLAY SOUND
        play({ id: 'chip' })

        updateMoney(money => money - CHIP);
        updateBet(bet => bet + CHIP);

        let copy = [...bettingChips];
        if(copy.length !== 3) {
            copy.push(CHIP);

            updateBettingChips(copy);
        }
        else {
            let ALL = [...allBettingChips];
            ALL.push(copy.shift());
            copy.push(CHIP);

            updateAllBettingChips(ALL);
            updateBettingChips(copy);
        }
    }

    function undoBet() {
        if(bet > 0) {
            
            if(allBettingChips.length > 0) {
                let ALL = [...allBettingChips];
                let BET = [...bettingChips];

                let CHIP = BET.pop();
                BET.unshift(ALL.pop());

                updateMoney(money => money + CHIP);
                updateBet(bet => bet - CHIP);

                updateAllBettingChips(ALL);
                updateBettingChips(BET);
            }
            else {
                let BET = [...bettingChips];
                let CHIP = BET.pop();

                updateMoney(money => money + CHIP);
                updateBet(bet => bet - CHIP);
                updateBettingChips(BET);
            }
           
        }
    }

    function shuffleCards() {

        //CREATE DECK OF CARDS
        for (let symbol = 0; symbol < CARD_SYMBOLS.length; symbol++) {
            for (let value = 0; value < CARD_VALUES.length; value++) {
                CARDS.current.push([CARD_VALUES[value], CARD_SYMBOLS[symbol], true]);
            }
        }

        //SHUFFLE CARDS
        for (let card = 0; card < CARDS.current.length; card++) {
            let RANDOM_INDEX = Math.floor(Math.random() * CARDS.current.length);

            let temp = CARDS.current[card];
            CARDS.current[card] = CARDS.current[RANDOM_INDEX];
            CARDS.current[RANDOM_INDEX] = temp;
        }

    }

    function getCard() {
        if (CARDS.current.length === 0) {
            shuffleCards();
        }

        return CARDS.current.pop();
    }



    function dealCards() {
        let count = 1;

        INTERVAL.current = setInterval(() => {
            if (count % 2 !== 0) {
                givePlayerACard();
            }
            else if(count === 2) {
                giveDealerACard();
            }
            else {
                giveDealerACard(false);
                clearInterval(INTERVAL.current);
                return;
            }
            count++;

        }, 500)

    }


    function giveDealerACard(show = true) {
        const CARD = getCard();

        if(!show) {
            CARD[2] = false;
        }

        //PLAY SOUND
        play({id: 'card'})
    
        updateDealer(prev => {
            let COPY = [...prev.cards, CARD];

            let TOTAL;
            if(!show) {
                TOTAL = prev.total;
            }
            else {
                TOTAL = countCards(COPY);
            }

            return {cards: COPY, total: TOTAL}
        })
    }

    function givePlayerACard(show = true) {
        const CARD = getCard();

        if(!show) {
            CARD[2] = false;
        }

        //PLAY SOUND
        play({ id: 'card' })
        
        updatePlayer(prev => {
            let COPY = [...prev.cards, CARD];
            let TOTAL;

            if(!show) {
                TOTAL = prev.total;
            }
            else {
                TOTAL = countCards(COPY);
            }

            return { cards: COPY, total: TOTAL }
        })
    }


    function START_GAME() {
        setStart(true);
        dealCards();
    }


    function RESET() {
        clearTimeout(TIMEOUT.current);
        resetGame();
    }

    /*
        GIVE PLAYER A NEW CARD
    */
    function hit() {
        setUserInput(false);
        givePlayerACard();
    }

    /*
        PLAYER STANDS AND DEALER DRAWS CARDS
    */
    function stand() {
        STAND.current = true;
        setUserInput(false);

        //FLIP DEALER'S CARD
        updateDealer(prev => {
            let COPY = prev.cards;
            COPY[1][2] = true;

            let TOTAL = countCards(COPY);
            return { cards: COPY, total: TOTAL }
        })

        INTERVAL.current = setInterval(() => {
            giveDealerACard();
        }, 1000)

    }

    /*
        PLAYER DOUBLES DOWN
    */
    function double() {
        STAND.current = true;
        DOUBLE.current = true;

        setUserInput(false);
        updateBet(bet => bet * 2);
        updateMoney(money => money - bet);
        givePlayerACard(false);

        TIMEOUT.current = setTimeout(() => {
            //FLIP DEALER'S CARD
            updateDealer(prev => {
                let COPY = prev.cards;
                COPY[1][2] = true;

                let TOTAL = countCards(COPY);
                return { cards: COPY, total: TOTAL }
            })
            INTERVAL.current = setInterval(() => {
                giveDealerACard();
            }, 1000)
        }, 750)


    }


    return (
        <div className="blackjack-main-container" onClick={(winner !== null) ? RESET : undefined}>
            {props.children}
            
            <div className="blackjack-table-container">

                <div className={`dealers-hand ${winner === 'player' ? 'blackjack-losing-hand' : ""} `}>
                    {dealer.cards.map((card, cardID, array) =>
                        <Card key={cardID.toString()} card={card} cardID={cardID} count={array.length} />
                    )}

                    {dealer.total !== 0 && <Number type={'hand'} number={dealer.total} />}
                </div>


                <div className='blackjack-middle-container'>
                    {(!start && bet === 0) &&
                        <img src={PLACE_YOUR_BET} alt="place your bet" className='place-your-bet'/>
                    }

                    {winner === 'player' ? <img draggable="false" src={WIN_IMAGE} alt="you win" className='blackjack-game-result' /> :
                        winner === 'draw' ? <img draggable="false" src={PUSH_IMAGE} alt="push" className='blackjack-game-result' /> :
                            winner === 'dealer' ? <img draggable="false" src={LOSE_IMAGE} alt="you lose" className='blackjack-game-result' /> : null
                    }

                    {(!start && bet > 0) &&
                        <button onClick={START_GAME} className='blackjack-deal-button'></button>
                    }

                    {(userInput && player.total < 21) &&
                        <button onClick={hit} title='HIT' className='blackjack-table-buttons'>
                            <img draggable="false" src={HIT_IMAGE} alt="hit" />
                        </button>
                    }

                    {userInput &&
                        <button onClick={stand} title='STAND' className='blackjack-table-buttons'>
                            <img draggable="false" src={STAND_IMAGE} alt="stand" />
                        </button>
                    }

                    {(userInput && player.cards.length < 3 && (money >= bet)) &&
                        <button onClick={double} title='DOUBLE' className='blackjack-table-buttons'>
                            <img draggable="false" src={DOUBLE_IMAGE} alt="double" />
                        </button>
                    }

                   

                </div>


                <div className={`players-hand ${winner === 'dealer' ? 'blackjack-losing-hand' : "" }`}>

                    {player.cards.map((card, cardID, array) =>
                        <Card key={cardID.toString()} card={card} cardID={cardID} count={array.length} />
                    )}

                    {player.total !== 0 && <Number type={'hand'} number={player.total}/> }

                    <div className="players-bet" onClick={!start ? undoBet : undefined}>
                        {bettingChips.map((chip, chipID) =>
                            <img
                                draggable="false"
                                key={chipID}
                                src={require(`../../images/Blackjack/money-chip-${chip}.png`)}
                                alt="chip"
                                className='players-bet-chips'
                                style={{ left: `-${chipID * 0}%`, top: `-${chipID * 10}%` }}
                            />
                        )}

                        {bet !== 0 && <Number type={'bet'} number={bet} />}
                    </div>
                </div>

            </div>
            
            
            <Bankroll 
                money={money} 
                placeBet={placeBet} 
                start={start}/>
        </div>
    )
}


export default Blackjack;
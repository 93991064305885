
import { useState } from 'react';
import '../css/Homescreen.css'
import startButton from '../images/start.png'
import loading from '../images/loading.gif'

function Homescreen(props) {
    
    const {soundLoaded, onClick, color} = props;
    const [hover, setHover] = useState(false);


    return (
        <div className="homescreen-main-container">
            {props.children}

            {!soundLoaded ? 
                <img src={loading} alt="Loading..." className='homescreen-loading-gif' /> :
                <button
                    onClick={onClick}
                    onMouseEnter={() => { setHover(true) }}
                    onMouseLeave={() => { setHover(false) }}
                    className="homescreen-start-button"
                    style={{ backgroundColor: hover ? (color ? color : '#555555') : 'transparent' }}
                >
                    <img draggable="false" src={startButton} alt="start" className="homescreen-start-button-image" />
                </button>
            }
           
        </div>
    )
}

export default Homescreen;

import { useState } from "react";

import { Helmet } from 'react-helmet';

import Colors from "./Colors";
import Homescreen from "../../components/Homescreen";
import HomepageButton from "../../components/HomepageButton";

import title from '../../images/4Colors/4colors-title.gif'

import useSound from 'use-sound';
import SOUNDS from '../../audio/4colors-sounds.mp3';

function Game() {
    const [start, setStart] = useState(false);
    const [soundLoaded, setSoundLoaded] = useState(false)

    const [play] = useSound(SOUNDS, {
        sprite: {
            red: [0, 550],
            blue: [1840, 550],
            green: [3690, 550],
            yellow: [5530, 550],
            levelup: [7380, 920],
            lose: [9230, 920]
        },
        onload: () => {
            setSoundLoaded(true)
        }
    });

    return(
        <>
            <Helmet>
                <title>4Colors Memory Game</title>
                <meta name="description" content="Color Pattern Memory Game. Watch and memorize the color pattern then copy the pattern by pressing the color buttons. The pattern keeps increasing as you move on to the next levels. Can you reach the final level 99?" />
            </Helmet>
            
            {!start ?
            <Homescreen soundLoaded={soundLoaded} onClick={() => { setStart(true) }} color={"rgba(250, 235, 215, 0.635)"}>
                <img draggable="false" src={title} alt="title" className="homescreen-title colors-homescreen-title" />
            </Homescreen> : <Colors play={play}> <HomepageButton></HomepageButton> </Colors>}
        </>
       
    )

}

export default Game;

import { useRef, useState } from "react";

import { Helmet } from 'react-helmet';

import Homescreen from "../../components/Homescreen";
import Blackjack from "./Blackjack";
import HomepageButton from "../../components/HomepageButton";
import MuteButton from "../../components/MuteButton";

import title from '../../images/Blackjack/blackjack-title.png';

import useSound from 'use-sound';
import SOUNDS from '../../audio/blackjack-sounds.mp3';

function Game() {
    const [start, setStart] = useState(false);
    const [soundLoaded, setSoundLoaded] = useState(false)
    const MUTE_SOUNDS = useRef(false);

    const [play, { sound }] = useSound(SOUNDS, {
        sprite: {
            chip: [0, 230],
            card: [1840, 230],
            win: [3690, 1250],
            lose: [5530, 1250],
            push: [7380, 1000],
        },
        onload: () => {
            setSoundLoaded(true)
        },
    });

    function handleMuteButtonClick() {
        if(!MUTE_SOUNDS.current) {
            sound.mute(true);
        }
        else {
            sound.mute(false);
        }

        MUTE_SOUNDS.current = !MUTE_SOUNDS.current;

    }

    return (
        <>
            <Helmet>
                <title>Pixel Blackjack Game</title>
                <meta name="description" content="Pixel art Blackjack Game. Play against a dealer to see who has the highest total count in cards. A 21 count of cards is the best hand in the game. You lose automatically if the count of your cards go over 21. Good luck!" />
            </Helmet>

            {!start ?
            <Homescreen soundLoaded={soundLoaded} onClick={() => { setStart(true) }} color={"rgb(18, 113, 18)"}>
                <img draggable="false" src={title} alt="title" className="homescreen-title blackjack-homescreen-title" />
            </Homescreen> :
            <Blackjack play={play}>
                <HomepageButton />
                <MuteButton onClick={handleMuteButtonClick} />
            </Blackjack>}
        </>
        
    )

}

export default Game;
import './App.css';

import { Route, Routes } from 'react-router-dom';
//import { Suspense, lazy } from 'react';


import Homepage from './pages/Home';

import Tictactoe from './pages/TicTacToe';
import Maze from './pages/Maze';
import ColorsX4 from './pages/4Colors';
import MemoryCards from './pages/MemoryCards';
import Blackjack from './pages/Blackjack';

import { inject } from '@vercel/analytics';
inject();

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage/>} />
        <Route path="/tictactoe" element={<Tictactoe />} />
        <Route path="/maze" element={<Maze />} />
        <Route path="/4colors" element={<ColorsX4 />} />
        <Route path="/memorycards" element={<MemoryCards />} />
        <Route path="/blackjack" element={<Blackjack />} />
        <Route path="*" element={<Homepage/>} />
      </Routes>
    </div>
  );
}

export default App;


import { useEffect, useState } from 'react';
import { generateMaze } from '../../utils/Maze Generation';



function Board(props) {
    //console.log("MAZE RENDER!")
    const {START, endGame} = props;

    let [maze, setMaze] = useState(new Array(20).fill(-9999).map(() => new Array(20).fill(-9999)));
    let [player, setPlayer] = useState({});

    let [message, showMessage] = useState(true);

    useEffect(() => {
        if(START) {
            START_GAME();
        }
    },[START])

    

    function START_GAME() {
        const MAZE = generateMaze(20);
        setMaze(MAZE);
        setPlayer({ x: 0, y: 0 });
    }


    function handleOnKeyDown(event) {
        if(START) {
            const MAZE = maze;
            let PlayerPosition = { ...player }

            if (event.key === "ArrowUp") {
                PlayerPosition.x--;
            }
            else if (event.key === "ArrowDown") {
                PlayerPosition.x++;

            }
            else if (event.key === "ArrowLeft") {
                PlayerPosition.y--;

            }
            else if (event.key === "ArrowRight") {
                PlayerPosition.y++;

            }

            let row = PlayerPosition.x;
            let col = PlayerPosition.y;

            if ((row !== -1 && row !== 20) && (col !== -1 && col !== 20) && MAZE[row][col] !== -9999) {

                if(row === 19 && col === 19) {
                    setPlayer(PlayerPosition);
                    endGame("won");
                }
                else {
                    setPlayer(PlayerPosition);
                }

            }
        }
    }

    return (
        <div tabIndex={-1} className='maze' onKeyDown={handleOnKeyDown} onClick={() => {showMessage(false)}}  onBlur={() => {showMessage(true)}} >
            {props.children}
            
            {(message && START) &&
                <div className='maze-message-background'>
                    <img draggable="false" src={require('../../images/Maze/maze-message.png')} alt="message" className='maze-message-image' />
                </div>
            }

            {maze.map((row, rowID) =>
                <div key={rowID} className={"rows"}>
                    {row.map((value, cellID) =>
                        <div key={cellID} className={`cell ${value === 1000 ? "exit" : value === -1 ? "path" : ""}`}>
                            {(player.x === rowID && player.y === cellID) && 
                                <div className='maze-player'></div>
                            }

                        </div>
                    )}
                </div>
            )}

        </div>
    )
}





export default Board;
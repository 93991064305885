
let Maze = new Array(20).fill(-9999).map(() => new Array(20).fill(-9999));
let rows;
let cols;

let stack = [];
let builder; 


/*
     Returns an array of all the neighbours of the cell 'position' (TOP CELL, BOTTOM CELL, LEFT CELL, RIGHT CELL)
   */
function getNeighbours (position) {
    //TOP CELL
    let topCell = { ...position };
    topCell.x--;

    //BOTTOM CELL
    let bottomCell = { ...position };
    bottomCell.x++;

    //LEFT CELL
    let leftCell = { ...position };
    leftCell.y--;

    //RIGHT CELL
    let rightCell = { ...position };
    rightCell.y++;

    return [topCell, bottomCell, leftCell, rightCell];
}


/*
      Returns false if any of the neighbouring cells of 'position' has already been visited.
  
      Returns true if 'position' has no visited neighbours.
    */
function hasNoVisitedNeighbours (previousPosition, position) {
    //let maze = this.Maze;


    let neighbours = getNeighbours(position);

    for (let cell of neighbours) {
        if ((cell.x !== -1 && cell.x !== rows) && (cell.y !== -1 && cell.y !== cols) && (Maze[cell.x][cell.y] !== -9999) && (cell.x !== previousPosition.x || cell.y !== previousPosition.y)) {
            return false;
        }
    }

    return true;
}

/*
  Returns an array of valid neighbouring cells at the current 'position'
  A neighbour cell is valid if:
  - It is not a cell outside the maze.
  - It has not been visited yet
  - If the unvisited neighbour cell also has no unvisited neighbours.
*/
function checkNeighbours (position) {
    //let maze = this.Maze;

    let neighbours = getNeighbours(position);

    let valid_cells = [];

    for (let cell of neighbours) {
        if ((cell.x !== -1 && cell.x !== rows) && (cell.y !== -1 && cell.y !== cols) && (Maze[cell.x][cell.y] === -9999) && hasNoVisitedNeighbours(position, cell)) {
            valid_cells.push(cell)
        }
    }

    return valid_cells;
}

export function generateMaze(size) {
    rows = size;
    cols = size;

    Maze = new Array(rows).fill(-9999).map(() => new Array(cols).fill(-9999));

    //SETUP MAZE
    Maze[rows - 4][cols - 1] = 2;
    Maze[rows - 1][cols - 4] = 2;

    //SETUP BUILDER
    builder = { x: 0, y: 0 };

    //SETUP STACK
    stack.push(builder);

    while (stack.length !== 0) {
        let current_cell = stack.pop();

        //SET THE AGENT'S POSITION TO THE CURRENT/TOP CELL OF THE STACK
        builder = { ...current_cell };
        Maze[current_cell.x][current_cell.y] = -1;

        /*
        GET AN ARRAY OF ALL THE UNVISITED NEIGHBOURS THAT ALSO HAS NO UNVISTED NEIGHBOURS OF ITS OWN, EXCLUDING THE current_cell
        EMPTY ARRAY = NO VALID NEIGHBOURS TO MOVE TO
        NON EMPTY ARRAY = VALID NEIGHBOURS/CELLS TO MOVE TO. (AT LEAST 1 VALID CELL TO MOVE ON TO)
        */
        let valid_cells = checkNeighbours(current_cell);
        if (valid_cells.length !== 0) {
            stack.push(current_cell);

            let randomMove = Math.floor(Math.random() * valid_cells.length);

            stack.push(valid_cells[randomMove]);

        }
    }

    if (Maze[rows - 1][cols - 1] !== -1) {
        Maze[rows - 1][cols - 2] = -1;
        Maze[rows - 1][cols - 3] = -1;
        Maze[rows - 2][cols - 1] = -1;
        Maze[rows - 3][cols - 1] = -1;
    }
    //SET THE EXIT OF THE MAZE
    Maze[rows - 1][cols - 1] = 1000;

    //Uncover the helper preset visited cells
    Maze[rows - 4][cols - 1] = -1;
    Maze[rows - 1][cols - 4] = -1;

    //Uncover the cells next to the helper visited cells so that the helper cells are connected to a path.
    Maze[rows - 5][cols - 1] = -1;
    Maze[rows - 6][cols - 1] = -1;
    Maze[rows - 1][cols - 5] = -1;
    Maze[rows - 1][cols - 6] = -1;

    //SET A NEGATIVE VALUE TO ALL DEADENDS IN THE MAZE
    //this.setRandomOpenCells();


    
    return Maze;
}



function Cards(props) {

    const {cardsClicked, cardsFound, cards, handleCardClick} = props

    return (
        <div className="memory-cards-container">
            {props.children}

            {cards.map((card, cardID) =>
                <div 
                    key={cardID} id={cardID} 
                    className="memory-card" 
                    onClick={handleCardClick} 
                    style={cards.length === 16 ? {width: '24%', height: "24%"} :
                            cards.length === 25 ? {width: '19%', height: '19%'} :
                            cards.length === 36 ? {width: "16%", height: '16%'} : null}>
                    <div className={`memory-card-face ${(cardsClicked.card1 === cardID) || (cardsClicked.card2 === cardID) || cardsFound.has(cardID) ? 'flip-card' : ""} `}>
                        <img draggable="false" src={require('../../images/MemoryCards/memory-card-front.png')} alt="front" className="memory-card-front" />
                        <img draggable="false" src={require(`../../images/MemoryCards/memory-card-${card}.png`)} alt={card} className="memory-card-back" />
                    </div>

                </div>
            )}
        </div>
    )
}

export default Cards;

import { useEffect, useState } from "react";


function BackgroundCard(props) {

    const {game, showGame, direction} = props;
    const [render, setRender] = useState(false);

    useEffect(() => {
        if (game === showGame) {
            setRender(true);
        }
    }, [game, showGame])


    function onAnimationEnd() {
        if (game !== showGame) {
            setRender(false);
        }
    }
    
    return (
        render && (
            <div
                className={`slider-background-card slider-background-${game} ${game === showGame ? "main-background-card" : ""}`}
                style={direction === "right" ? { animation: `${(game === showGame) ? 'backgroundCardRightMount .25s ease' : 'backgroundCardUnmount .25s ease'}` } :
                        direction === "left" ? { animation: `${(game === showGame) ? 'backgroundCardLeftMount .25s ease' : 'backgroundCardUnmount .25s ease'}` } : null
                }
                onAnimationEnd={onAnimationEnd}
            >


            </div> 
        )
    )   
    
   
}


export default BackgroundCard;

import { useRef, useState } from "react";

import { Helmet } from 'react-helmet';

import Homescreen from "../../components/Homescreen";
import Menu from "./Menu";
import MemoryCards from "./MemoryCards";
import HomepageButton from "../../components/HomepageButton";
import MuteButton from "../../components/MuteButton";

import title from '../../images/MemoryCards/memory-cards-title.gif'

import useSound from 'use-sound';
import SOUNDS from '../../audio/memorycards-sounds.mp3'

const CARDS_16X = ["fries", "fries", "burger", "burger", "icecream", "icecream", "taco", "taco", "milk", "milk", "pizza", "pizza", "candy", "candy", "milkshake", "milkshake"]

const CARDS_25X = ["fries", "fries", "burger", "burger", "icecream", "icecream", "taco", "taco", "milk", "milk", "pizza", "pizza", "evil", "candy", "candy", "milkshake", "milkshake", "chicken", "chicken", "coffee", "coffee",
    "cookie", "cookie", "donut", "donut"]

const CARDS_36X = ["fries", "fries", "burger", "burger", "icecream", "icecream", "taco", "taco", "milk", "milk", "pizza", "pizza", "candy", "candy", "milkshake", "milkshake", "chicken", "chicken", "coffee", "coffee",
                    "cookie", "cookie", "donut", "donut", "drinks", "drinks", "egg", "egg", "lollipop", "lollipop", "popcorn", "popcorn", "popsicle", "popsicle", "chocolate", "chocolate"]

function Game() {
    const [start, setStart] = useState(false);
    const [gameState, setGameState] = useState(null);
    const [soundLoaded, setSoundLoaded] = useState(false)
    
    const [cards, setCards] = useState([]);
    
    const MUTE_SOUNDS = useRef(false);

    const [play, { sound }] = useSound(SOUNDS, {
        sprite: {
            notfound: [1800, 1000],
            found: [3690, 1000],
            win: [5530, 1800],
            lose: [7380, 1800],
            evil: [9230, 3000]
        },
        onload: () => {
            setSoundLoaded(true)
        }
    });

    function handleMuteButtonClick() {
        if (!MUTE_SOUNDS.current) {
            sound.mute(true);
        }
        else {
            sound.mute(false);
        }

        MUTE_SOUNDS.current = !MUTE_SOUNDS.current;

    }

    function handleStartButton() {
        setStart(true);
        setGameState('MENU');
        
    }

    function handleMenuButton(MODE) {
        //console.log(MODE);
        setGameState('GAME');

        if(MODE === '16x') {
            setCards([...CARDS_16X]);
        }
        else if(MODE === "25x") {
            setCards([...CARDS_25X]);
        }
        else if(MODE === '36x') {
            setCards([...CARDS_36X]);
        }
    
    }

    function openMenu() {
        setGameState('MENU');
    }

    return (
        <>
            <Helmet>
                <title>Food Cards | Memory Game</title>
                <meta name="description" content="Memory game. Find all card pairs without losing all of your lives. You win if you find all card pairs. You lose if you use up all your lives. Good luck!" />
            </Helmet>
            

            {!start && 
                <Homescreen soundLoaded={soundLoaded} onClick={handleStartButton} color={"rgb(141, 99, 180)"}>
                    <img draggable="false" src={title} alt="title" className="homescreen-title memory-cards-homescreen-title" />
                </Homescreen>
            }

            {gameState === 'MENU' &&
                <Menu handleMenuButton={handleMenuButton}>
                    <HomepageButton/>
                </Menu>
            }

            {gameState === 'GAME' && 
                <MemoryCards play={play} CARDS={cards}>
                    <HomepageButton/>
                    <button className="mc-open-menu-button" onClick={openMenu}></button>
                    <MuteButton onClick={handleMuteButtonClick} />
                </MemoryCards>
            }

        </>
        
    )

}

export default Game;
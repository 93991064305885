
import { useState } from 'react';
import '../css/Play.css'

function Play(props) {

    const {play, results, onClick} = props;

    const [hover, setHover] = useState(false);


    function handleButtonClick() {
        setHover(false);
        onClick();
    }

    return (
        !play &&
            <>
                <div className='play-background'>

                </div>

                <div className='play-main-container'>
                    {results !== "" &&
                        <img draggable='false' className='game-results' src={require(`../images/${results}-art.png`)} alt="game result" />
                    }
                    <button className='play-main-button' onClick={handleButtonClick}>
                        <img 
                            draggable='false' 
                            className='play-button-image' 
                            src={require(`../images/play${results !== "" ? "-again" : ""}${hover ? "-hover" : ""}.png`)} 
                            alt="play" 
                            onMouseEnter={() => {setHover(true)}}
                            onMouseLeave={() => {setHover(false)}}    
                        />    
                    </button>
                </div>
            </>
    )
}

export default Play;
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Helmet } from 'react-helmet';

import BackgroundCard from "./BackgroundCard";
import GameCard from "./GameCard";


import './HomepageSlider.css'

const GAMES = ["tictactoe", "maze", "4colors", "memorycards", "blackjack"];

let GAME_INDEX = 0;

function HomepageSlider(props) {

    //const {switchLayout} = props;

    const [showGame, setShowGame] = useState(GAME_INDEX);
    const [direction, setDirection] = useState("")

    const navigate = useNavigate();

    const keyDown = useRef(false);


    useEffect(() => {
        return () => {
            GAME_INDEX = showGame;
        }
    }, [showGame])


    function handleSliderButtonClick(event) {
        let number;
        if(event.key === "ArrowRight" && !keyDown.current) {
            keyDown.current = true;
            number = 1;
        } 
        else if(event.key === "ArrowLeft" && !keyDown.current) {
            keyDown.current = true;
            number = -1;
        }
        else if(event.key !== undefined || keyDown.current) {
            return;
        }
        else {
            number = parseInt(event.target.id, 10);
        }
        let newNumber = showGame + number;

        if(number === 1) {
            setDirection("right");
        }
        else {
            setDirection("left");
        }
        
        if(newNumber === GAMES.length) {
            setShowGame(0);
        }
        else if (newNumber === -1) {
            setShowGame(GAMES.length - 1);
        }
        else {
            setShowGame(newNumber);
        }
        
    
    }

    function navigateToGame(event) {
        const GAME = event.target.id;
        navigate(`${GAME}`);
    }


    return (
        <div tabIndex={-1} className="homepage-slider-main-container" onKeyDown={handleSliderButtonClick} onKeyUp={() => {keyDown.current = false}}>

            <Helmet>
                <title>Pixel Games</title>
                <meta name="description" content="Pixel art meets Gaming | Play free games online. Play games like Tic-Tac-Toe, Blackjack, Color Games, Memory Games, Maze Games, and many more!" />
            </Helmet>

            <img draggable="false" src={require('../../images/Home/pixel-games.png')} alt="title" className="homepage-main-title"/>
            
            {/*<button className="homepage-slider-header-button" onClick={switchLayout}>
            </button>*/}



            {GAMES.map((game, gameID) =>
                <BackgroundCard key={gameID.toString()} game={game} showGame={GAMES[showGame]} direction={direction}>

                </BackgroundCard>
            )}
            
            <div id={-1} className="slider-buttons slider-left" onClick={handleSliderButtonClick}></div>
            <div id={1} className="slider-buttons slider-right" onClick={handleSliderButtonClick}></div>

            {GAMES.map((game, gameID) => 
                <GameCard key={gameID.toString()} game={game} showGame={GAMES[showGame]} direction={direction} onClick={navigateToGame} >

                </GameCard>
            )}
            
        </div>
    )
}


export default HomepageSlider;
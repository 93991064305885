
import { useRef, useState } from "react";

import { Helmet } from 'react-helmet';

import Homescreen from "../../components/Homescreen";
import Maze from "./Maze";
import HomepageButton from "../../components/HomepageButton";
import MuteButton from "../../components/MuteButton";

import useSound from "use-sound";
import SOUNDS from '../../audio/maze-sounds.mp3'


import title from '../../images/Maze/maze-title.gif';

function Game() {
    const [start, setStart] = useState(false);
    const [soundLoaded, setSoundLoaded] = useState(false);
    const MUTE_SOUNDS = useRef(false);

    const [play, { stop, sound }] = useSound(SOUNDS, {
        sprite: {
            win: [0, 2800],
            lose: [6400, 2800],
            music: [12800, 31000],
        },
        onload: () => {
            setSoundLoaded(true)
        }
    });

    function handleMuteButtonClick() {
        if (!MUTE_SOUNDS.current) {
            sound.mute(true);
        }
        else {
            sound.mute(false);
        }

        MUTE_SOUNDS.current = !MUTE_SOUNDS.current;

    }

    return (
        <> 
            <Helmet>
                <title>Pixel Maze Game</title>
                <meta name="description" content="Pixel Maze Game. Race against time to find the exit of a 20x20 maze. You win if you exit the maze before the time runs out. Generate a random maze instantly and start playing!" />
            </Helmet>
            
            {!start ?
            <Homescreen soundLoaded={soundLoaded} onClick={() => { setStart(true) }} color={'rgb(0, 166, 208)'}>
                <img draggable="false" src={title} alt="title" className="homescreen-title maze-homescreen-title" />
            </Homescreen> :
            <Maze play={play} stop={stop}>
                <HomepageButton />
                <MuteButton onClick={handleMuteButtonClick} />
            </Maze>} 
        </>
    )
}



export default Game;



import MODE_16X from '../../images/MemoryCards/memorycards-16x.png';
import MODE_25X from '../../images/MemoryCards/memorycards-25x.png';
import MODE_36X from '../../images/MemoryCards/memorycards-36x.png';


function Menu(props) {


    const { handleMenuButton } = props;


    return (
        <div className="memorycards-main-container">
            {props.children}
            <div className='memorycards-menu-container'>
                <button id='16x' className='memorycards-menu-button mc-mode-16x' onClick={(event) => handleMenuButton(event.target.id)}>
                    <img id='16x' draggable="false" src={MODE_16X} alt="16x"/>
                </button>
                <button id='25x' className='memorycards-menu-button mc-mode-25x' onClick={(event) => handleMenuButton(event.target.id)}>
                    <img id='25x' draggable="false" src={MODE_25X} alt="25x" />
                </button>
                <button id='36x' className='memorycards-menu-button mc-mode-36x' onClick={(event) => handleMenuButton(event.target.id)}>
                    <img id='36x' draggable="false" src={MODE_36X} alt="36x"/>
                </button>
            </div>
        </div>
    )
}

export default Menu;



function Levels(props) {

    const {level, setDifficultyLevel} = props;

    return (
        <div className='levels-container'>
            <img draggable="false" src={require('../../images/TicTacToe/easy-art.png')} alt="easy" id='easy' className={`levels ${level === 'easy' ? 'highlight' : ''}`} onClick={setDifficultyLevel} />
            <img draggable="false" src={require('../../images/TicTacToe/medium-art.png')} alt="medium" id='medium' className={`levels ${level === 'medium' ? 'highlight' : ''}`} onClick={setDifficultyLevel} />
            <img draggable="false" src={require('../../images/TicTacToe/hard-art.png')} alt="hard" id='hard' className={`levels ${level === 'hard' ? 'highlight' : ''}`} onClick={setDifficultyLevel} />
        </div>
    )
}


export default Levels;
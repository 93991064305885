
import { useRef, useState } from 'react';

import { Helmet } from 'react-helmet';

import Homescreen from '../../components/Homescreen';
import TicTacToe from './Tictactoe';
import HomepageButton from '../../components/HomepageButton';
import MuteButton from '../../components/MuteButton';

import title from '../../images/TicTacToe/ttt-title.gif';

import useSound from 'use-sound';
import SOUNDS from '../../audio/tictactoe-sounds.mp3'

function Game() {
    const [start, setStart] = useState(false)
    const [soundLoaded, setSoundLoaded] = useState(false)
    const MUTE_SOUNDS = useRef(false);

    const [play, { sound }] = useSound(SOUNDS, {
        sprite: {
            player: [0, 230],
            agent: [1840, 230],
            win: [3690, 460],
            lose: [5530, 460],
            switch: [7380, 230],
        },
        onload: () => {
            setSoundLoaded(true)
        }
    });


    function handleMuteButtonClick() {
        if (!MUTE_SOUNDS.current) {
            sound.mute(true);
        }
        else {
            sound.mute(false);
        }

        MUTE_SOUNDS.current = !MUTE_SOUNDS.current;

    }

    return (
        <>
            <Helmet>
`               <title>Tic-Tac-Toe</title>
                <meta  name="description" content="Pixel Tic-Tac-Toe game. Play against a trained robot that has 3 different difficulty levels (Easy, Medium, and Hard). Try to win in all 3 levels!" />
            </Helmet>
            
            {!start ?
            <Homescreen soundLoaded={soundLoaded} onClick={() => { setStart(true) }} color={'rgb(79, 194, 156)'}>
                <img draggable="false" src={title} alt="title" className="homescreen-title ttt-homescreen-title" />
            </Homescreen> :
            <TicTacToe play={play} >
                <HomepageButton />
                <MuteButton onClick={handleMuteButtonClick} />
            </TicTacToe>}
        </>
       
    )
}


export default Game;
import { useState } from "react";

import '../css/MuteButton.css'

import SOUND_OFF from '../images/sound-off.png';
import SOUND_ON from '../images/sound-on.png';

function MuteButton(props) {

    const {onClick} = props;

    const [mute, setMute] = useState(false);


    function handleMuteButtonClick() {
        setMute(mute => !mute);
        onClick();
    }


    return (
        <button className="mute-button" onClick={handleMuteButtonClick}>
            <img draggable="false" src={!mute ? SOUND_ON : SOUND_OFF} alt="mute" className="mute-button-image" />
        </button>
    )
}

export default MuteButton;
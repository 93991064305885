
import { useEffect, useRef, useState } from 'react';

import './MemoryCards.css'

import Cards from './Cards';
import Play from '../../components/Play';
import Lives from './Lives';



function MemoryCards(props) {
    //console.log("MEMORY CARDS !")

    const {play, CARDS} = props;

    const [start, setStart] = useState(false);
    const [results, setResults] = useState("");
    const [lives, setLives] = useState(0);

    const [cards, setCards] = useState(CARDS);
    const [cardsClicked, setCardsClicked] = useState({card1: null, card2: null})

    const [cardsFound, setCardsFound] = useState(new Set());

    const DISABLE_CLICKS = useRef(false);
    const SHUFFLE_TIMEOUT = useRef();
    const CARDS_TIMEOUT = useRef(); 

    //CLEAR TIMEOUT AND INTERVAL ON UNMOUNT
    useEffect(() => {
        return () => {
            clearTimeout(SHUFFLE_TIMEOUT.current);
            clearTimeout(CARDS_TIMEOUT.current);
        }
    }, [])

    useEffect(() => {
        //console.log("USE EFFECT !")
        if(cards.length === 25 && (cards[cardsClicked.card1] === "evil" || cards[cardsClicked.card2] === "evil")) {

            DISABLE_CLICKS.current = true;
            CARDS_TIMEOUT.current = setTimeout(() => {
                //PLAY SOUND
                play({ id: "evil" });
                
                CARDS_TIMEOUT.current = setTimeout(() => {
                    setCardsClicked({ card1: null, card2: null });
                    setCardsFound(new Set());
                    setLives(lives => lives - 1);

                    DISABLE_CLICKS.current = false;
                },1500)
            }, 500)
            return;
        }

        if (cardsClicked.card1 !== null && cardsClicked.card2 !== null) {

            CARDS_TIMEOUT.current = setTimeout(() => {
                
                const ALL_CARDS = [...cards];

                //CARDS ARE EQUAL
                if (ALL_CARDS[cardsClicked.card1] === ALL_CARDS[cardsClicked.card2]) {
                    let NEW_SET = new Set([...cardsFound]);
                    NEW_SET.add(cardsClicked.card1);
                    NEW_SET.add(cardsClicked.card2);

                    setCardsClicked({card1: null, card2: null})
                    setCardsFound(NEW_SET);

                    if ( (NEW_SET.size === cards.length) || (cards.length === 25 && NEW_SET.size === 24)) {
                        //END GAME, USER WON
                        setStart(false);
                        setResults("won");
                        play({id: "win"});
                       
                    }
                    else {
                        play({id: "found"});
                    }
                }
                //CARDS ARE NOT EQUAL
                else {
                    setCardsClicked({ card1: null, card2: null })
                    setLives(lives => lives - 1);

                    if (lives === 1) {
                        //END GAME, USER LOST
                        setStart(false);
                        setResults("lost");

                        play({ id: "lose" });
                       
                    }
                    else {                       
                        play({ id: "notfound" });
                    }
                }
            }, 500)
        }
    }, [cardsClicked, cards, cardsFound, lives, play])


    function shuffleCards() {
        let CARDS = [...cards];

        for (let i = 0; i < CARDS.length; i++) {
            let RANDOM_INDEX = Math.floor(Math.random() * CARDS.length);

            let temp = CARDS[i];
            CARDS[i] = CARDS[RANDOM_INDEX];
            CARDS[RANDOM_INDEX] = temp;
        }
        setCards(CARDS);
        
    }


    function startGame() {
        DISABLE_CLICKS.current = true;

        setStart(true);
        if(CARDS.length === 16) {
            setLives(6);
        }
        else if(CARDS.length === 25) {
            setLives(20);
        }
        else if(CARDS.length === 36) {
            setLives(25);
        }

        setCardsFound(new Set());

        SHUFFLE_TIMEOUT.current = setTimeout(() => {
            shuffleCards();
            DISABLE_CLICKS.current = false;
        }, 500)
    }


    function handleCardClick(event) {
        console.log(event);
        let cardID = event.target.id;
        const CARD_ID = parseInt(cardID, 10);

        if (!DISABLE_CLICKS.current && start) {
            if (cardsClicked.card1 === null && !cardsFound.has(CARD_ID)) {
                setCardsClicked((cards) => ({...cards, card1: CARD_ID}))
            }
            else if (cardsClicked.card2 === null && cardsClicked.card1 !== CARD_ID && !cardsFound.has(CARD_ID)) {
                setCardsClicked(cards => ({ ...cards, card2: CARD_ID }))
            }
        }

    }


    return (
        <div className='memorycards-main-container'>
            {props.children}
            <Play play={start} results={results} onClick={startGame} />

            <Cards cardsClicked={cardsClicked} cardsFound={cardsFound} cards={cards} handleCardClick={handleCardClick}  >
                <Lives lives={lives} />

            </Cards>
        </div>
    )
}

export default MemoryCards;
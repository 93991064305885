
import { useEffect, useRef, useState } from 'react';

import Board from './Board';
import Scoreboard from './Scoreboard';
import Levels from './Levels';

function TicTacToe(props) {
  
    const {play} = props;

    const [player, setPlayer] = useState('X');
    const [agent, setAgent] = useState('O');
    const [level, setLevel] = useState("easy");
    const [scoreboard, setScoreboard] = useState({ player: 0, draws: 0, agent: 0 })

    const DISABLE_BUTTONS = useRef()

    useEffect(() => {
        DISABLE_BUTTONS.current = false;
    }, [])

    //HANDLE DIFFICULTY BUTTONS
    function setDifficultyLevel(event) {
        if (!DISABLE_BUTTONS.current) {            
            const LEVEL = event.target.id;
            if(LEVEL !== level) {
                play({id: 'switch'})
                setLevel(LEVEL);
            }
        }
    }

    //SWITCH PLAYER SYMBOLS 
    function changeSymbol() {
        if (!DISABLE_BUTTONS.current) {
            play({ id: 'switch' })
            setPlayer(player === 'X' ? 'O' : 'X');
            setAgent(agent === 'X' ? 'O' : 'X');
        }
    }

    //UPDATE SCOREBOARD
    function updateScoreboard(WINNER) {
        //SCOREBOARD TEMPORARY COPY
        let SCOREBOARD = { ...scoreboard }
        
        if(SCOREBOARD[WINNER] < 99) {
            SCOREBOARD[WINNER]++;
            setScoreboard(SCOREBOARD);
        }
    }

    //SWITCH FUNCTION USED TO TEMPORARILY DISABLE ALL BUTTONS (LEVEL + SYMBOLS BUTTONS) WHEN THE WIN INTERVAL IS RUNNING IN THE BOARD COMPONENT
    function switchButtons() {
        if (!DISABLE_BUTTONS.current) {
            DISABLE_BUTTONS.current = true;
        }
        else {
            DISABLE_BUTTONS.current = false;
        }
    }


    return (
        <div className="tictactoe-main-container">
            {props.children}
            <Board player={player} agent={agent} level={level} updateScoreboard={updateScoreboard} switchButtons={switchButtons} play={play}>
                <Scoreboard player={player} agent={agent} scoreboard={scoreboard} changeSymbol={changeSymbol} />
            </Board>
            <Levels level={level} setDifficultyLevel={setDifficultyLevel} />

        </div>
    )
}


export default TicTacToe;

import { useCallback, useEffect, useRef, useState } from "react";

const colors = ['red', 'blue', 'green', 'yellow'];

function ColorButtons(props) {
    //console.log("COLOR BUTTONS RENDER!")
    const {start, level, sendGameResults, play} = props;

    const [colorClicked, setColorClicked] = useState(null);

    //ARRAY USED TO STORE THE COLOR PATTERN
    const COLOR_PATTERN = useRef([]);

    
    const PATTERN_INTERVAL = useRef();
    const BUTTON_TIMEOUT = useRef();

    const USER_CLICKS = useRef(0);

    const SHOW_PATTERN = useRef(true);
    const DISABLE_CLICK = useRef(true);

    const playSound = useCallback((COLOR) => {
        switch (COLOR) {
            case "red": 
                play({ id: 'red' })
                break;
            case "blue":
                play({ id: 'blue' })
                break;
            case "green":
                play({ id: 'green' })
                break;
            case "yellow":
                play({ id: 'yellow' })
                break;
            default:
        }
    }, [play])

    useEffect(() => {
        return () => {
            clearTimeout(BUTTON_TIMEOUT.current);
            clearInterval(PATTERN_INTERVAL.current);
        }
    }, [])

    useEffect(() => {
        //console.log("USE EFFECT !")
        if(start) {
            //RESET USER_CLICKS COUNT WHENEVER PLAY IS SET TO TRUE OR WHENEVER LEVEL CHANGES AND PLAY STAYS TRUE
            USER_CLICKS.current = 0;
            
            //ADD RANDOM COLOR TO THE CURRENT COLOR PATTERN
            const COLOR = colors[Math.floor(Math.random() * colors.length)];
            COLOR_PATTERN.current.push(COLOR);

            //TRAVERSE AND SHOWCASE COLOR PATTERN
            let index = 0;
            PATTERN_INTERVAL.current = setInterval(() => {
                playSound(COLOR_PATTERN.current[index]);
                setColorClicked(COLOR_PATTERN.current[index]);
                index++;


                BUTTON_TIMEOUT.current = setTimeout(() => {
                    setColorClicked(null);
                    if (index === COLOR_PATTERN.current.length) {
                        clearInterval(PATTERN_INTERVAL.current);
                        DISABLE_CLICK.current = false;
                        SHOW_PATTERN.current = false;
                    }
                }, 200)
            }, 700);


        }
        else {
            COLOR_PATTERN.current = [];
        }
    }, [start, level, playSound])

    function handleColorButtonClick(event) {
      
        if(!DISABLE_CLICK.current && !SHOW_PATTERN.current) {
            const COLOR = event.target.id;
            USER_CLICKS.current++;
            showColorClicked(COLOR);
        }
    }


    function showColorClicked(COLOR) {
        playSound(COLOR);
        setColorClicked(COLOR);

        //DISABLE USER CLICKS UNTIL TIMEOUT EXECUTES
        disableClicks();

        //clearTimeout(BUTTON_TIMEOUT.current);
        BUTTON_TIMEOUT.current = setTimeout(() => {
            setColorClicked(null);
            checkPattern(COLOR);
            disableClicks();
        }, 150)
        
    }

    function checkPattern(COLOR) {
        if(COLOR_PATTERN.current[USER_CLICKS.current - 1] !== COLOR) {
            DISABLE_CLICK.current = true;
            SHOW_PATTERN.current = true;
            sendGameResults('lost');
        }
        else if(USER_CLICKS.current === COLOR_PATTERN.current.length) {
            DISABLE_CLICK.current = true;
            SHOW_PATTERN.current = true;
            sendGameResults('win');
        }
    }

    function disableClicks() {
        DISABLE_CLICK.current = SHOW_PATTERN.current ? DISABLE_CLICK.current : !DISABLE_CLICK.current;
    }



    return (
        <div className="color-buttons-container">
            <div id='red' className={`color-buttons color-button-red ${colorClicked === 'red' ? "color-clicked" : ""} `} onClick={handleColorButtonClick}></div>
            <div id='blue' className={`color-buttons color-button-blue ${colorClicked === 'blue' ? "color-clicked" : ""} `} onClick={handleColorButtonClick}></div>
            <div id='green' className={`color-buttons color-button-green ${colorClicked === 'green' ? "color-clicked" : ""} `} onClick={handleColorButtonClick}></div>
            <div id='yellow' className={`color-buttons color-button-yellow ${colorClicked === 'yellow' ? "color-clicked" : ""} `} onClick={handleColorButtonClick}></div>
           
            {props.children}
        </div>
    )
}

export default ColorButtons;

import { useEffect, useRef, useState } from 'react';
import '../css/Timer.css'

function Timer(props) {

    const {start, time, endGame} = props;
    const [timer, setTimer] = useState(null);

    const INTERVAL = useRef();

    useEffect(() => {
        if(start) {
            setTimer(time);
            INTERVAL.current = setInterval(() => {
                setTimer((TIME) => TIME - 1);
            }, 1000)
        }

        return () => {
            clearInterval(INTERVAL.current);
        }
    },[start, time])

    
    useEffect(() => {
        if(timer === 0) {
            clearInterval(INTERVAL.current);
            endGame('lost');
        }
    },[timer, endGame])


    if(timer !== null) {
        return (
            <div className='timer-main-container'>
                <img src={require(`../images/TicTacToe/number${timer === 0 ? "0" : (timer / 10).toString()[0]}.png`)} alt="number" className='timer-numbers' />
                <img src={require(`../images/TicTacToe/number${timer % 10 === 0 ? "0" : (timer / 10).toString()[2]}.png`)} alt="number" className='timer-numbers' />            
            </div>
        )   
    }
}

export default Timer;


import Number from "./Number";

const CHIPS = [1,5,10,25,100];

function Bankroll(props) {

    const {money, placeBet, start} = props;


    function handleChipClick(event) {
        event.preventDefault();
        const CHIP_AMOUNT = parseInt(event.target.id, 10);
        placeBet(CHIP_AMOUNT);
    }

    return (
        <div className="bankroll-container">

            <Number type={'money'} number={money} />

            <div className="bankroll-chips-container">
                {CHIPS.map((chip, chipID) => 
                    <img 
                        draggable="false" 
                        key={chipID} id={chip} 
                        src={require(`../../images/Blackjack/money-chip-${chip}.png`)} 
                        alt="chip" 
                        className={`bankroll-chips ${(money < chip) || (start) ? "disable-chip" : ""}`} 
                        onClick={handleChipClick}/>
                )}
            </div>

        </div>
    )
}


export default Bankroll;
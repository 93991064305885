
import { useNavigate } from "react-router-dom";

import '../css/HomepageButton.css'

function HomepageButton() {

    const navigate = useNavigate()

    function handleHomepageButtonClick() {
        navigate('/');
    }

    return (
        <div className="homepage-button" onClick={handleHomepageButtonClick} ></div>
    )
}


export default HomepageButton;

import { useCallback, useEffect, useRef, useState } from 'react';
import './Tictactoe.css'

import policyX from '../../policies/policy_X.csv'
import policyO from '../../policies/policy_O.csv'
import Papa from 'papaparse';

/*  AGENT POLICIES 
    DATA USED BY AGENT TO DECIDED THE BEST MOVE TO MAKE IN ANY TICTACTOE GAME
*/
let policy_X = {};
let policy_O = {};

/* 
    CHECK THE TICTACTOE BOARD FOR A WINNER
*/
function checkBoard(board) {
    //let WINNING_CELLS = []
    let BOARD = [...board];

    //CHECK ALL ROWS ON THE BOARD FOR A WINNER
    let row;
    for (let cell = 0; cell < 3; cell++) {
        row = BOARD[cell * 3] + BOARD[cell * 3 + 1] + BOARD[cell * 3 + 2];
        if (row === "XXX") {
            //WINNING_CELLS = [cell * 3, cell * 3 + 1, cell * 3 + 2];
            return { winner: "X", cells: [cell * 3, cell * 3 + 1, cell * 3 + 2] };
        }
        else if (row === "OOO") {
            return { winner: "O", cells: [cell * 3, cell * 3 + 1, cell * 3 + 2] }
        }

    }

    //CHECK ALL COLUMNS ON THE BOARD FOR A WINNER
    let column;
    for (let cell = 0; cell < 3; cell++) {
        column = BOARD[cell] + BOARD[cell + 3] + BOARD[cell + 6];
        if (column === "XXX") {
            return { winner: "X", cells: [cell, cell + 3, cell + 6] };
        }
        else if (column === "OOO") {
            return { winner: "O", cells: [cell, cell + 3, cell + 6] };

        }
    }

    let d1 = BOARD[0] + BOARD[4] + BOARD[8];
    let d2 = BOARD[2] + BOARD[4] + BOARD[6];

    //CHECK ALL DIAGONALS ON THE BOARD FOR A WINNER
    if (d1 === "XXX") {
        return { winner: "X", cells: [0, 4, 8] };

    }
    else if (d1 === "OOO") {
        return { winner: "O", cells: [0, 4, 8] }
    }

    if (d2 === "XXX") {
        return { winner: "X", cells: [2, 4, 6] }

    }
    else if (d2 === "OOO") {
        return { winner: "O", cells: [2, 4, 6] }

    }


    const emptyCells = BOARD.find(element => element === 'E');

    //return "Tie" if there are no empty cells and there are no winners.
    if (emptyCells === undefined) {
        return {winner: "Tie", cells: []}
    }

    //return NULL if the current game has not concluded.
    return { winner: null, cells: [] }
}

function Board(props) {
    //console.log("BOARD RENDER!")
    const {player, agent, level, updateScoreboard, switchButtons, play} = props;

    const [board, updateBoard] = useState(["E", "E", "E", "E", "E", "E", "E", "E", "E"]);
    const [winner, updateWinner] = useState(null);

    //SET ALL BOARD CELLS WITH A LOW OPACITY
    const [hide, setHide] = useState(false);
    const [winCells, setWinCells] = useState(new Set());

    const TURN = useRef('X');
    const WIN_INTERVAL = useRef();
    const AGENT_TIMEOUT = useRef();

    const IS_BOARD_EMPTY = useRef(true);


    /*
        READ AND PREPARE CSV POLICIES ONCE WHEN THE COMPONENT MOUNTS
    */
    useEffect(() => {
        Papa.parse(policyX, {
            header: false,
            download: true,
            complete: (results) => {
                parseData(results.data, "policy_X")
            }
        });

        Papa.parse(policyO, {
            header: false,
            download: true,
            complete: (results) => {
                parseData(results.data, "policy_O")
            }
        });

        return () => {
            clearInterval(WIN_INTERVAL.current);
        }
    }, [])


    /*
        PARSE THE CSV FILES INTO EASY TO ACCESS JS OBJECTS
    */
    function parseData(data, policyType) {
        let policy = {}

        for (let pair = 0; pair < data.length; pair++) {
            policy[data[pair][0]] = data[pair][1];
        }

        policy[data[0][0]] = data[0][1]

        if(policyType === "policy_X") {
            policy_X = policy;
        }
        else if (policyType === "policy_O") {
            policy_O = policy;
        }
    }


    const restartGame = useCallback(() => {
        if(!IS_BOARD_EMPTY.current) {
            //console.log("RESTART GAME !")
            updateBoard(["E", "E", "E", "E", "E", "E", "E", "E", "E"]);
            updateWinner(null);
            setHide(false);
            setWinCells(new Set());
            //X PLAYER WILL ALWAYS GO FIRST
            TURN.current = 'X';
            IS_BOARD_EMPTY.current = true;
        }

        //IF AGENT IS X
        if (agent === 'X') {
            AGENT_TIMEOUT.current = setTimeout(() => {
                play({ id: 'agent' })
                updateBoard((BOARD) => {
                    let boardCopy = [...BOARD];
                    let CELL = Math.floor(Math.random() * 9);
                    boardCopy[CELL] = agent;

                    return boardCopy;
                });
                TURN.current = 'O';
                IS_BOARD_EMPTY.current = false;
            }, 300)
        }
    }, [agent, play])

    //RESTART GAME WHENEVER THE PLAYER || AGENT || LEVEL CHANGES IN PROPS
    useEffect(() => {

        restartGame();

        return () => {
            clearTimeout(AGENT_TIMEOUT.current);
        }
    }, [player, level, restartGame])


    /*
        UPDATES WINNER STATE, "WON" || "LOSE" || "DRAW"
        UPDATES SCOREBOARD
    */
    function handleGameResults(WINNER) {
        if(WINNER === player) {
            updateWinner("won");
            updateScoreboard("player")
        }   
        else if(WINNER === agent) {
            updateWinner("lost");
            updateScoreboard("agent")
        }
        else {
            updateWinner("draw");
            updateScoreboard("draws")
        }
    }
  

    /* 
        WILL CALCULATE THE BEST MOVE FOR THE AGENT BASED ON CURRENT SELECTED DIFFICULTY LEVEL
        EASY: 100% RANDOM MOVE, 0% BEST MOVE
        MEDIUM: 50% RANDOM MOVE, 50% BEST MOVE
        HARD: 0% RANDOM MOVE, 100% BEST MOVE
    */
    function calculateBestMove(board) {
        let CELL; 
        let MAX = -9999;
        let EPSILON = null;

        //CHOOSE POLICY BASED ON AGENT'S SYMBOL
        let policy;
        if(agent === 'O') {
            policy = policy_O;
        }
        else if(agent === 'X') {
            policy = policy_X;
        }

        //CHOOSE EPSILON BASED ON SELECTED DIFFICULTY LEVEL
        if(level === 'easy') {
            EPSILON = 100;
        }
        else if(level === 'medium') {
            EPSILON = 50;
        }
        else if(level === 'hard') {
            EPSILON = 0;
        }
 

        //COPY OF GAME BOARD
        let BOARD = [...board];

        //RANDOM NUMBER BETWEEN 1-100
        let RANDOM_NUMBER = Math.floor(Math.random() * 100);

        //PICK A RANDOM EMPTY CELL ON THE BOARD
        if(RANDOM_NUMBER < EPSILON) {
            let openCells = [];
            for (let cell = 0; cell < 9; cell++) {
                if (BOARD[cell] === 'E') {
                    openCells.push(cell);
                }
            }
            let randomIndex = Math.floor(Math.random() * openCells.length)
            CELL = openCells[randomIndex];
        }
        //PICK THE ABSOLUTE BEST MOVE ON THE BOARD
        else {
            for (let cell = 0; cell < 9; cell++) {
                if (BOARD[cell] === 'E') {
                    //MAKE A SUB COPY OF BOARD
                    let tempBoard = [...BOARD];
                    //ASSIGNED CELL TO AGENT'S SYMBOL
                    tempBoard[cell] = agent;

                    /*CHECK THE POLICY IF TEMPBOARD HAS A GREATER MAX VALUE THAN THE THE CURRNT MAX VALUE
                        IF YES - UPDATE CURRENT MAX VALUE TO THE NEW MAX VALUE FROM POLICY + UPDATE CELL TO cell
                        IF NO - NO UPDATE TO CURRENT MAX VALUE AND CURRENT CELL     
                    */
                    tempBoard = tempBoard.join('');
                    if (policy[tempBoard] > MAX) {
                        MAX = policy[tempBoard];
                        CELL = cell;
                    }
                }
            }
        }

        //RETURN CELL INDEX:
        //WILL EITHER BE A RANDOM MOVE OR BEST MOVE
        return CELL;

    }

    
    function checkForWinner(BOARD) {
        const RESULTS = checkBoard(BOARD);
        let WIN_CELLS = RESULTS.cells;

        if(RESULTS.winner === 'X' || RESULTS.winner === 'O') {
            switchButtons();
            setHide(true);
            WIN_INTERVAL.current = setInterval(() => {
                //console.log("INTERVAL!")
                if (WIN_CELLS.length !== 0) {
                    setWinCells((set) => new Set([...set, WIN_CELLS.shift()]));
                    if (RESULTS.winner === player) {
                        play({id: 'player'})
                    }
                    else {
                        play({ id: 'agent' })
                    }
                }
                else {
                    if (RESULTS.winner === player) {
                        play({ id: 'win' })
                    }
                    else {
                        play({ id: 'lose' })
                    }
                    clearInterval(WIN_INTERVAL.current);
                    handleGameResults(RESULTS.winner);
                    switchButtons();
                }
            }, 250);
        }
        else if (RESULTS.winner === 'Tie') {
            handleGameResults(RESULTS.winner);
        }
        else {
            if(TURN.current === agent) {
                TURN.current = player;
            }
            else {
                TURN.current = agent;
                AGENT_TIMEOUT.current = setTimeout(() => {
                    agentsTurn(BOARD);
                }, 300)
            }
        }
    }

    //HANDLE AGENTS TURN
    function agentsTurn(board) {
        //GET COPY OF GAME BOARD
        let BOARD = [...board];
    
        //CALCULATE BEST MOVE BASED ON CURRENT LEVEL
        let CELL = calculateBestMove(BOARD);

        //UPDATE BOARD CELL WITH AGENT'S SYMBOL
        BOARD[CELL] = agent;

        //PLAY SOUND
        play({ id: 'agent' })

        //UPDATE BOARD
        updateBoard([...BOARD]);
        
        //CHECK BOARD FOR WINNER
        checkForWinner(BOARD);
    }
    
    //HANDLE PLAYERS TURN
    function playersTurn(cell) {
        //GET COPY OF GAME BOARD
        let BOARD = [...board];

        //UPDATE CELL WITH PLAYER'S SYMBOL
        BOARD[cell] = player;

        //PLAY SOUND
        play({ id: 'player' })

        //UPDATE BOARD
        updateBoard([...BOARD]);

        if(IS_BOARD_EMPTY.current) {
            IS_BOARD_EMPTY.current = false;
        }
        
        //CHECK BOARD FOR WINNER
        checkForWinner(BOARD)
    }


    /*
        FUNCTION THAT HANDLES BOARD CLICKS FROM USER
    */
    function boardClick(event) {
        //GET CELL ID AND CONVERT IT TO AN INTEGER
        let cell = event.target.id.slice(1);
        cell = parseInt(cell, 10);

        if (!hide && winner === null && TURN.current === player && board[cell] === 'E') {
            playersTurn(cell);
        }
    }
    
    return(
        <div className='tictactoe-board'>
            {props.children}
            {board.map((value, cellID) => 
                <div 
                    key={cellID} id={`c${cellID}`} 
                    className={`cells ${hide ? 'hide-cells' : ''} ${winCells.has(cellID) ? 'highlight-cells' : ""}`} 
                    onClick={boardClick}>
                        {value !== "E" &&
                            <img draggable="false" src={require(`../../images/TicTacToe/symbol_${value}.png`)} alt={value} className='cells-image'/>
                        }
                </div>
            )}
            
            {winner !== null && 
                <>
                <img draggable="false" src={require(`../../images/TicTacToe/${winner}-art.png`)} alt={winner} className={`message message-${winner}`} />
                <div className='restart-button' onClick={restartGame}></div>
                </>
            }
        </div>
    )
}


export default Board;